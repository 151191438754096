
require.register("uc.micro/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "uc.micro");
  (function() {
    'use strict';

exports.Any = require('./properties/Any/regex');
exports.Cc  = require('./categories/Cc/regex');
exports.Cf  = require('./categories/Cf/regex');
exports.P   = require('./categories/P/regex');
exports.Z   = require('./categories/Z/regex');
  })();
});