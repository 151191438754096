
require.register("mdurl/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "mdurl");
  (function() {
    'use strict';


module.exports.encode = require('./encode');
module.exports.decode = require('./decode');
module.exports.format = require('./format');
module.exports.parse  = require('./parse');
  })();
});