
require.register("markdown-it/lib/rules_core/normalize.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "markdown-it");
  (function() {
    // Normalize input string

'use strict';


// https://spec.commonmark.org/0.29/#line-ending
var NEWLINES_RE  = /\r\n?|\n/g;
var NULL_RE      = /\0/g;


module.exports = function normalize(state) {
  var str;

  // Normalize newlines
  str = state.src.replace(NEWLINES_RE, '\n');

  // Replace NULL characters
  str = str.replace(NULL_RE, '\uFFFD');

  state.src = str;
};
  })();
});