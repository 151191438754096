
require.register("subspace-console/Path.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "subspace-console");
  (function() {
    "use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Path = void 0;

var _Task = require("subspace-console/Task");

var Path = {};
exports.Path = Path;
  })();
});